import React, { Component } from 'react';
import './App.css';

import First from './First/First';
import Second from './Second/Second';
import Third from './Third/Third';

class App extends Component {
  render() {
     return (
      <div className="App">
        <First></First>
        <Second></Second>
        <Third></Third>
      </div>
    );
  }
}

export default App;