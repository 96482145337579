import React, { Component } from 'react';
import '../App.css';

class Right extends Component {
  render() {
    return (
      <div className="Right">
        <div className="RightInner">
          <div className="SmallLogo"></div>
        <p>Specialist, interventni radiolog sem od leta 2003. Ves ta čas se ukvarjam s tem modernim 
          in izjemno zanimivim področjem medicine in sem v zadnjih 15. letih opravil več tisoč interventnih
          posegov. Filozofija interventne radiologije je izvajanje za pacienta minimalno invazivnih in minimalno
          obremenjujočih posegov. Zato vodenje le-teh poteka s pomočjo “žive” slike modernih diagnostičnih metod:
          ultrazvoka, diaskopije, CT-ja in magnetne resonance. Eden izmed takšnih posegov je tudi zdravljenje
          krčnih žil. Take terapevtske posege, ki imajo tudi velik estetski učinek, bom kmalu opravljal tudi
          v okviru in prostorih uveljavljene in prepoznavne ustanove v Ljubljani… 
          <br></br>
          <br></br>
          Vesel bom, če me kontaktirate!
        </p>
        </div>
      </div>
    );
  }
}

export default Right;