import React, { Component } from 'react';
import '../App.css';
import Right from "./Right";
import Left from "./Left";

class Second extends Component {
  render() {
    return (
      <div className="Second">
        <Left></Left>
        <Right></Right>
      </div>
    );
  }
}

export default Second;