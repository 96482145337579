import React, { Component } from 'react';
import '../App.css';
import Logo from '../Logo';

class First extends Component {

  constructor(props) {
  super(props);
  this.handleScroll = this.handleScroll.bind(this);
};

componentDidMount() {
  window.addEventListener('scroll', this.handleScroll, true);
};

componentWillUnmount() {
  window.removeEventListener('scroll', this.handleScroll);
};

handleScroll(event) {
  if (this.scrollTop > 147) {
    /* add class sticky */
  } else /* fullscreenerror */ {
    /* nekaj drugega */
  }
};

  render() {
    return (
      <div className="First">
      <Logo></Logo>
      </div>
    );
  };
}

export default First;