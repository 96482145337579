import React, { Component } from 'react';
import './App.css';
import logo from './Assets/matjaz-vrtovec-white-logo.png';

class Logo extends Component {
  render() {
    return (        
      <div className="LogoContainer">
            <img src={logo} alt="logo-matjaz-vrtovec" className="logo"/>
      </div>
    );
  }
}

export default Logo;
