import React, { Component } from 'react';
import '../App.css';
import portrait from "../Assets/portrait.jpg";

class Left extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <=  366;

    return (
      <div className="Left">
        <div className="LeftInner">
          <img src={portrait} alt="dr-matjaz-vrtovec" className="Portrait"/>
          { isMobile ? <p>asist. dr. Matjaž Vrtovec, dr. med., spec. rad.</p> : null }
        </div>
      </div>
    );
  }
}

export default Left;