import React, { Component } from 'react';
import '../App.css';

class Third extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <=  366;


    return (

      <div className="Third">
        <p className="KontaktneInformacije-first">{ isMobile ? null : "asist. dr. Matjaž Vrtovec, dr. med., spec. rad." }</p>
        <br></br>
        <p className="KontaktneInformacije-second">
        Brezplačno mnenje in svetovanje vsak torek in četrtek med 15. In 20. uro na +38640/239/230 
        ali na elektronskem naslovu matjaz@vrtovec.net
        </p>
      </div>
    );
  }
}

export default Third;